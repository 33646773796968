<template lang="pug">
  div
    v-icon.mr-1(v-if="workbook.is_team_canvas" disabled) mdi-account-group-outline
    v-tooltip(bottom v-else-if="workbook.shared")
      template(v-slot:activator="{ on, attrs }")
        v-icon.mr-1(v-on="on" @click="makeWorkbookPrivate" :disabled="!isOwnWorkbook") mdi-account-multiple-outline
      span Make private
    v-tooltip(bottom v-else)
      template(v-slot:activator="{ on, attrs }")
        v-icon.mr-1(v-on="on" @click="makeWorkbookPublic") mdi-account-outline
      span Make public
    GenericDialog(
      title="Rename Canvas"
      :on-save="renameWorkbook"
      :can-save="canSave"
      width="400px"
    )
      template(v-slot:activator="{ on: on1 }")
        v-tooltip(bottom)
          template(v-slot:activator="{ on: on2, attrs }")
            v-icon.mr-1(v-on="{ ...on1, ...on2 }" :disabled="workbook.is_team_canvas || !isOwnWorkbook") mdi-pencil-outline
          span Rename
      template(v-slot:content="")
        v-row
          v-col
            v-text-field(
              v-model="$v.iwbTitle.$model"
              :error-messages="genericValidationFunction('Title', $v.iwbTitle.$model)"
              label="Title"
              hide-details
            )
    GenericDialog(
      :title="`Duplicate Canvas \"${ workbook.title }\"`"
      :on-save="duplicateWorkbook"
      :can-save="canDuplicate"
      width="400px"
    )
      template(v-slot:activator="{ on: on1 }")
        v-tooltip(bottom)
          template(v-slot:activator="{ on: on2, attrs }")
            v-icon.mr-1(size="20px" v-on="{ ...on1, ...on2 }") mdi-content-copy
          span Duplicate
      template(v-slot:content="")
        v-row
          v-col
            v-text-field(
              v-model="$v.iwbTitle.$model"
              :error-messages="genericValidationFunction('Title', $v.iwbTitle.$model)"
              label="Title"
            )
            v-select(
              v-model="targetProject"
              :items="projects"
              label="Project"
              class="required"
              item-value="uuid"
              item-text="name")
    GenericDialog(
      :title="`Delete Canvas \"${ workbook.title }\"`"
      :on-delete="deleteWorkbook"
      width="400px"
    )
      template(v-slot:activator="{ on: on1 }")
        v-tooltip(bottom)
          template(v-slot:activator="{ on: on2, attrs }")
            v-icon(v-on="{ ...on1, ...on2 }" :disabled="workbook.is_team_canvas || !isOwnWorkbook") mdi-delete-outline
          span Delete
      template(v-slot:content="")
        span Are you sure you want to delete the Invention Canvas &nbsp;
        b {{ workbook.title }}
        span ?
</template>

<script>

import { maxLength, required } from 'vuelidate/lib/validators'
import GenericDialog from '@/components/common/GenericDialog.vue'
import { genericValidationFunction } from '@/utils/validations'
import { authStore } from '@/store/modules/auth'
import { mapState } from 'pinia'
import { projectsStore } from '@/modules/projects/store'
import { useCanvasStore } from '@/modules/canvas/store'

export default {
  name: 'WorkbookActionsDialogs',
  components: { GenericDialog },
  props: {
    workbook: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      iwbTitle: this.workbook.title,
      targetProject: this.currentProjectId
    }
  },
  computed: {
    ...mapState(projectsStore, {
      projects: 'allActive',
      currentProjectId: 'currentProjectId'
    }),
    canSave() {
      return !this.$v.iwbTitle.$invalid
    },
    canDuplicate() {
      return Boolean(this.canSave && this.targetProject)
    },
    isOwnWorkbook() {
      return this.workbook.created_by.id === authStore().profile.user_id
    }
  },
  watch: {
    'workbook.title'() {
      this.iwbTitle = this.workbook.title
    }
  },
  created() {
    this.targetProject = this.currentProjectId
    if (!projectsStore().allProjects.length) projectsStore().fetchAll()
  },
  validations: {
    iwbTitle: {
      required,
      maxLength: maxLength(256)
    }
  },
  methods: {
    genericValidationFunction,
    makeWorkbookPublic() {
      return useCanvasStore().patchCanvas(
        this.workbook,
        { shared: true },
        'There was an error while trying to make the Canvas public',
        'Successfully made the Canvas public'
      )
    },
    makeWorkbookPrivate() {
      return useCanvasStore().patchCanvas(
        this.workbook,
        { shared: false },
        'There was an error while trying to make the Canvas private',
        'Successfully made the Canvas private'
      )
    },
    deleteWorkbook() {
      return useCanvasStore().deleteCanvas(this.workbook.uuid)
    },
    renameWorkbook() {
      return useCanvasStore().patchCanvas(
        this.workbook,
        { title: this.iwbTitle },
        'There was an error while trying to rename the Canvas',
        'Successfully renamed the Canvas'
      )
    },
    async duplicateWorkbook() {
      return useCanvasStore().duplicateCanvas(this.workbook.uuid, {
        title: this.iwbTitle,
        project: this.targetProject
      })
    }
  }
}
</script>
