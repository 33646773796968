import { FILE_TYPES, makeExportHeaders } from '../interfaces/exports';
import http from '../plugins/http';
const ENDPOINT = 'triggers';
export function getDocs(params) {
    // @ts-ignore
    return http({
        url: ENDPOINT,
        method: 'post',
        data: params,
        meta: {
            cancelPrevReq: true
        }
    });
}
export function fetchForUrls(urls) {
    return http.post(`${ENDPOINT}/fetch_for_urls/`, { urls });
}
export function getDoc(docId, params) {
    return http({
        url: `${ENDPOINT}/${docId}/`,
        method: 'get',
        params
    });
}
export function triggersSearch(params) {
    return http.get(`${ENDPOINT}/search/`, { params, timeout: 30000 });
}
export function getScatter(params) {
    return http({
        url: `${ENDPOINT}/problem-mining/scatter/`,
        method: 'get',
        params,
        timeout: 30000
    });
}
export function heatmapData(params) {
    return http({
        url: `${ENDPOINT}/problem-mining/heatmap/`,
        method: 'get',
        params,
        timeout: 30000
    });
}
export function heatmapAnnotations(params) {
    return http({
        url: `${ENDPOINT}/problem-mining/heatmap-annotations/`,
        method: 'get',
        params,
        timeout: 30000
    });
}
export function getCollectedProblems(params) {
    return http({
        url: 'collected-problems/',
        method: 'get',
        params
    });
}
export function collectProblem(data) {
    return http({
        url: 'collected-problems/',
        method: 'post',
        data
    });
}
export function discardProblem(id) {
    return http({
        url: `collected-problems/${id}`,
        method: 'delete'
    });
}
export function autocompleteFindTriggers(field, text, searchParams) {
    return http.post('autocomplete/find_triggers/', {
        field,
        text: text || '',
        ...searchParams
    });
}
export function autocompleteAssignee(text, searchParams) {
    return http.post('autocomplete/opportunity_atlas/', {
        field: 'assignee',
        text: text || '',
        ...searchParams
    });
}
export function autocompleteTriggerHunting(field, text, searchParams, signal) {
    return http.post('autocomplete/trigger_hunting/', {
        field,
        text: text || '',
        ...searchParams,
        size: 30
    }, { signal });
}
export function standardsDocsStatistics(params) {
    return http.get(`${ENDPOINT}/standards-docs-statistics/`, { params });
}
export function getTriggersExcel(params, exportDataType = FILE_TYPES.XLSX) {
    const exportHeaders = exportDataType ? makeExportHeaders(exportDataType.dataType) : {};
    return http({
        ...exportHeaders,
        url: `${ENDPOINT}/export/`,
        method: 'get',
        params,
        timeout: 10000
    });
}
export function getManualTriggersExcel(params, exportDataType = FILE_TYPES.XLSX) {
    const exportHeaders = exportDataType ? makeExportHeaders(exportDataType.dataType) : {};
    return http({
        ...exportHeaders,
        url: `${ENDPOINT}/export/`,
        data: params,
        method: 'post'
    });
}
export function bookmarkTrigger(params) {
    return http.post(`${ENDPOINT}/bookmark/`, params);
}
export function deleteBookmarkedTrigger(id) {
    return http.delete(`${ENDPOINT}/bookmark/${id}/`);
}
export function getBookmarkedTriggers(params) {
    return http.get(`${ENDPOINT}/bookmark/`, { params });
}
export function bookmarkedAutocomplete(params, signal) {
    return http.get(`${ENDPOINT}/bookmark/autocomplete/`, { params, signal });
}
export function getConnectedFeatures(params, signal) {
    return http.get(`${ENDPOINT}/connected_features/`, { params, signal });
}
export function connectedFeaturesHistogram(params) {
    return http.get(`${ENDPOINT}/feature-histogram/`, { params });
}
export const getSolutions = (query) => {
    return http.get(`${ENDPOINT}/solutions/`, { params: { query } });
};
