import { STANDARDIZATION_RANGES_TO_VALUE, STADARDIZATION_RANGES } from './constants';
export const getStandardizationScoreLabel = (score, short) => {
    if (score < STANDARDIZATION_RANGES_TO_VALUE.low)
        return short ? 'L' : 'Low';
    if (score < STANDARDIZATION_RANGES_TO_VALUE.medium)
        return short ? 'M' : 'Med';
    return short ? 'H' : 'High';
};
export const getStandardizationScoreValue = (score) => {
    if (score === 0.01)
        return 0.01;
    if (score < STANDARDIZATION_RANGES_TO_VALUE.low)
        return 0;
    if (score < STANDARDIZATION_RANGES_TO_VALUE.medium)
        return 0.3;
    return 1;
};
export const formatStandardizationScore = (scores) => {
    const startScore = scores[0];
    const endScore = scores[1];
    // if scores are same then we should return range for that score
    // if startScore = 0.01 or 0 and endScore = 0.01 or 0
    // we will return [0, 0.29] or [0.01, 0.29] -> which are the ranges for low score
    // if startScore = 0.5 and endScore = 0.5
    // we will return [0.3, 0.64] -> which are the ranges for medium score
    // if startScore = 0.99 or 1 and endScore = 0.99 or 1
    // we will return [0.65, 1] -> which are the ranges for high score
    if (startScore === endScore) {
        if (startScore === 0.01)
            return STADARDIZATION_RANGES.low;
        if (startScore < 0.3)
            return STADARDIZATION_RANGES.low_all;
        if (startScore < 0.65)
            return STADARDIZATION_RANGES.medium;
        return STADARDIZATION_RANGES.high;
    }
    // if end score is equal to 0.5 which is slider value for `medium score`
    // then we should return [0, 0.64] or [0.01, 0.64]
    if (endScore === 0.5) {
        return [startScore === 0.01 ? 0.01 : 0, 0.64];
    }
    return [getStandardizationScoreValue(scores[0]), getStandardizationScoreValue(scores[1])];
};
