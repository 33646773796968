import store from '@/store';
import { memoize } from 'lodash';
import { fetchForUrls } from '../../../api/triggers';
import { dateToIsoDate, DocumentType } from '../../../utils/constants';
import { mergeDeepRight } from '../../../utils/lodashExtended';
export const createEmptyTrackedTrigger = () => {
    return {
        id: -1,
        created_by: {
            id: '',
            username: ''
        },
        project_id: '',
        essential_features: [],
        essential_features_ic: [],
        essential_features_ic_ids: [],
        source: null,
        website: '',
        title: '',
        url: '',
        trigger_date: dateToIsoDate(new Date()),
        created_at: '',
        tracked_trigger_id: 0,
        virtual_id: '',
        content: '',
        rating: '',
        is_golden: false,
        doc_type: DocumentType.JOURNAL,
        assignee: '',
        patent_number: '',
        topics: [],
        location_x: undefined,
        location_y: undefined
    };
};
export const createEmptyTrigger = (overrides) => {
    const defaultValues = {
        assignee: '',
        patent_number: '',
        title: '',
        content: '',
        url: '',
        triggerDate: dateToIsoDate(new Date()),
        doc_type: DocumentType.JOURNAL,
        projects: [],
        source: null,
        location_x: undefined,
        location_y: undefined,
        essential_features: []
    };
    if (!overrides)
        return defaultValues;
    return mergeDeepRight(defaultValues, overrides);
};
export const fetchTriggerForUrl = memoize(async (url) => {
    try {
        return (await fetchForUrls([url])).data?.triggers[0];
    }
    catch (e) {
    }
});
export const mapEssentialFeatures = (essentialFeatures) => {
    if (!essentialFeatures)
        return;
    return essentialFeatures.map((ef) => ({
        type: ef.type?.toUpperCase() ?? 'PROBLEM',
        content: ef.content ?? '',
        hunch: ef.hunch ?? ''
    }));
};
export const getTriggerTitle = (trigger, fromEs) => {
    return trigger.doc_type?.toLowerCase() === 'standard' && trigger.title ? trigger.title : fromEs.title;
};
export const printWarningMessages = (warningMessages) => {
    if (warningMessages.length) {
        store.commit('snackbar/show', {
            color: 'warning',
            message: warningMessages.join('</br>'),
            timeout: 15000
        });
    }
};
const extractMessages = (data) => {
    return Array.isArray(data) ? data : Object.values(data);
};
export const showErrorMessage = (e, warningMessages) => {
    if (e.response?.status === 400) {
        warningMessages.push(...extractMessages(e.response.data));
    }
    else {
        store.commit('snackbar/show', {
            color: 'error',
            message: 'Failed to save. Please contact support.'
        });
        throw e;
    }
};
export const showSuccessMessages = (projectNames, update = false) => {
    if (projectNames.length && !update) {
        const message = `Trigger successfully added to tracker for projects ${projectNames.join(', ')}.`;
        store.commit('snackbar/show', { color: 'success', message });
    }
};
