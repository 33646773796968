export const STADARDIZATION_RANGES = {
    low_all: [0, 0.29],
    low: [0.01, 0.29],
    medium: [0.3, 0.64],
    high: [0.65, 1]
};
export const STANDARDIZATION_RANGES_TO_VALUE = {
    low: 0.3,
    medium: 0.65,
    high: 1
};
