import store from '@/store';
import { authStore } from '@/store/modules/auth';
import { throttle } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { getProjectsTrackedTriggers } from '../../../api/projects';
import { createTrackedTrigger, deleteTrackedTrigger, patchTrackedTrigger } from '../../../api/trackedTriggers';
import { isTrackedTrigger } from '../../../interfaces/tracker';
import { dateToIsoDate, DocumentType } from '../../../utils/constants';
import { ensureUrlHasProtocol } from '../../../utils/strings';
import { projectsStore } from '../../projects/store';
import { TRACKED_TRIGGER_SOURCE } from '../constants';
import { createEmptyTrackedTrigger, createEmptyTrigger, fetchTriggerForUrl, getTriggerTitle, mapEssentialFeatures, printWarningMessages, showErrorMessage, showSuccessMessages } from '../utils/triggerTracking';
import { useTrackedTriggersStore } from './trackedTriggers';
export const useCustomTriggerDialogStore = defineStore('customTriggerDialog', () => {
    const dialogOpen = ref(false);
    const trackTriggerDialogTitle = ref('Add custom trigger');
    const activeProjectForTT = ref(null);
    const projectsTrackedTriggers = ref([]);
    const trackedTrigger = ref(createEmptyTrackedTrigger());
    const trigger = ref(createEmptyTrigger());
    const savedProjects = ref([]);
    const essentialFeature = ref(createEmptyEssentialFeature());
    const trackedTriggersStore = useTrackedTriggersStore();
    const projectStore = projectsStore();
    const openCustomTriggerDialog = async () => {
        $reset();
        activeProjectForTT.value = projectStore.currentProject;
        await fetchProjectsTrackedTriggers();
        trackTriggerDialogTitle.value = 'Add custom trigger';
        dialogOpen.value = true;
    };
    const trackTrigger = async (trigger, openDialog) => {
        trackTriggerDialogTitle.value = 'Track trigger';
        activeProjectForTT.value = projectStore.currentProject;
        await setTrigger(trigger, openDialog, TRACKED_TRIGGER_SOURCE.EVO_TRIGGER_TABLE);
        if (!openDialog) {
            await saveTrackedTrigger();
        }
    };
    const editTrigger = (trigger) => {
        $reset();
        trackTriggerDialogTitle.value = 'Edit trigger';
        activeProjectForTT.value = projectStore.currentProject;
        setTrigger(trigger, true, TRACKED_TRIGGER_SOURCE.EVO_TRIGGER_TABLE);
    };
    function $reset() {
        dialogOpen.value = false;
        trackTriggerDialogTitle.value = 'Add custom trigger';
        activeProjectForTT.value = null;
        trackedTrigger.value = createEmptyTrackedTrigger();
        trigger.value = createEmptyTrigger();
        savedProjects.value = [];
        essentialFeature.value = createEmptyEssentialFeature();
    }
    function createEmptyEssentialFeature() {
        return {
            type: 'ABILITY',
            content: '',
            hunch: ''
        };
    }
    function disabledProjects(project) {
        return project.tracked_triggers.some((i) => i.title === trigger.value.title || i.url === trigger.value.url);
    }
    function alreadyInInput(project) {
        return trigger.value.projects.some((i) => i.uuid === project.uuid);
    }
    async function fetchProjectsTrackedTriggers() {
        const params = {
            archived: false,
            completed: false
        };
        projectsTrackedTriggers.value = (await getProjectsTrackedTriggers(params)).data;
        trigger.value.projects = projectsTrackedTriggers.value.filter(project => (disabledProjects(project) && !alreadyInInput(project)) || activeProjectForTT.value?.uuid === project.uuid);
        savedProjects.value = cloneDeep(trigger.value.projects);
    }
    const deleteEssentialFeatureFromStore = (index) => {
        if (trigger.value.essential_features?.length) {
            trigger.value.essential_features.splice(index, 1);
        }
    };
    async function setTrigger(t, openDialog = true, source = null) {
        if (!isTrackedTrigger(t)) {
            const fromEs = await fetchTriggerForUrl(t.url);
            if (fromEs) {
                const content = t.doc_type?.toLowerCase() === 'standard' ? (t.content ?? '') : fromEs?.content?.join(' ') ?? '';
                trigger.value = createEmptyTrigger({
                    title: getTriggerTitle(t, fromEs),
                    content,
                    url: ensureUrlHasProtocol(fromEs.url),
                    source,
                    location_x: t.location_x,
                    location_y: t.location_y,
                    assignee: t.assignee ?? '',
                    patent_number: t.patent_number ?? '',
                    triggerDate: fromEs.trigger_date ?? fromEs.date ?? dateToIsoDate(new Date()),
                    doc_type: t.doc_type ? t.doc_type.toLowerCase() : DocumentType.MEDIA,
                    essential_features: mapEssentialFeatures(t.essential_features) ?? fromEs.essential_features?.map(ef => ({
                        type: ef.type.toUpperCase(),
                        content: ef.text,
                        hunch: ''
                    })) ?? []
                });
            }
            else {
                const docType = t.doc_type.toLowerCase();
                trigger.value = createEmptyTrigger({
                    content: t.content,
                    essential_features: [{
                            type: t.trigger_type,
                            content: t.text,
                            hunch: ''
                        }],
                    assignee: t.assignee ?? '',
                    patent_number: t.patent_number ?? '',
                    title: t.doc_title,
                    doc_type: docType,
                    url: t.url,
                    location_x: t.location_x,
                    location_y: t.location_y
                });
            }
        }
        else {
            trackedTrigger.value = t;
            trigger.value = {
                title: t.title,
                content: Array.isArray(t.content) ? t.content.join(' ') : t.content ?? '',
                url: ensureUrlHasProtocol(t.url),
                triggerDate: t.trigger_date ? t.trigger_date : dateToIsoDate(new Date()),
                doc_type: t.doc_type,
                projects: [],
                location_x: t.location_x,
                location_y: t.location_y,
                assignee: t.assignee ?? '',
                patent_number: t.patent_number ?? '',
                source,
                essential_features: mapEssentialFeatures(t.essential_features) ?? []
            };
        }
        dialogOpen.value = openDialog;
        activeProjectForTT.value = projectStore.currentProject;
        await fetchProjectsTrackedTriggers();
    }
    const efChanged = computed(() => {
        const efsTrackedTrigger = trackedTriggersStore.trackedTriggers.find((trigger) => trigger.url === trackedTrigger.value.url)?.essential_features;
        return isEqual(mapEssentialFeatures(efsTrackedTrigger), trigger.value.essential_features);
    });
    const nothingToSave = computed(() => {
        if (trackedTrigger.value.id < 0)
            return false;
        return trigger.value.title === trackedTrigger.value.title &&
            trigger.value.url === trackedTrigger.value.url &&
            trigger.value.content ===
                trackedTrigger.value.content &&
            trigger.value.triggerDate ===
                trackedTrigger.value.trigger_date &&
            efChanged.value &&
            isEqual(sortBy(savedProjects.value, 'uuid'), sortBy(trigger.value.projects, 'uuid'));
    });
    async function saveTriggerInProject(project, warningMessages, quotedSavedProjectNames) {
        if (nothingToSave.value)
            return false;
        try {
            const shouldDelete = !trigger.value.projects.some(p => p.uuid === project.uuid);
            if (shouldDelete) {
                const ttUuid = savedProjects.value
                    .find(p => p.uuid === project.uuid)?.tracked_triggers
                    .find(tt => tt.url === trigger.value.url)?.uuid;
                if (ttUuid) {
                    return deleteTrackedTrigger(ttUuid);
                }
            }
            const docType = trigger.value.doc_type.toLowerCase();
            const data = {
                title: trigger.value.title,
                url: trigger.value.url,
                project_id: project.uuid,
                trigger_date: trigger.value.triggerDate ? dateToIsoDate(new Date(trigger.value.triggerDate)) : dateToIsoDate(new Date()),
                doc_type: docType,
                user_id: authStore().profile.user_id,
                content: trigger.value.content,
                source: trigger.value.source,
                location_x: trigger.value.location_x,
                location_y: trigger.value.location_y,
                assignee: trigger.value.assignee ?? '',
                patent_number: trigger.value.patent_number ?? '',
                essential_features: trigger.value?.essential_features ?? []
            };
            const shouldUpdate = trackedTriggersStore.trackedTriggers.find((trigger) => trigger.url === trackedTrigger.value.url);
            if (shouldUpdate) {
                await patchTrackedTrigger(trackedTrigger.value.id, data);
            }
            else {
                await createTrackedTrigger(data);
            }
            quotedSavedProjectNames.push(`"${project.name}"`);
            trigger.value.essential_features = [];
            trackedTrigger.value = createEmptyTrackedTrigger();
        }
        catch (e) {
            showErrorMessage(e, warningMessages);
        }
    }
    async function saveTrackedTrigger() {
        const warningMessages = [];
        const quotedSavedProjectNames = [];
        await Promise.all(trigger.value.projects
            .filter(p => !disabledProjects(p) || p.uuid === activeProjectForTT.value?.uuid)
            .map(p => saveTriggerInProject(p, warningMessages, quotedSavedProjectNames)));
        printWarningMessages(warningMessages);
        showSuccessMessages(quotedSavedProjectNames);
        dialogOpen.value = false;
        if (activeProjectForTT.value?.uuid)
            await trackedTriggersStore.fetchTrackedTriggers();
    }
    const deleteTrackedTriggerById = async (id, projectName) => {
        try {
            await deleteTrackedTrigger(id);
            store.commit('snackbar/show', {
                color: 'success',
                message: `Trigger successfully deleted from project ${projectName}.`
            });
        }
        catch (e) {
            store.commit('snackbar/show', {
                color: 'error',
                message: 'Failed to delete trigger. Please contact support.'
            });
        }
    };
    return {
        $reset,
        dialogOpen,
        trackTriggerDialogTitle,
        activeProjectForTT,
        projectsTrackedTriggers,
        trackedTrigger,
        trigger,
        createEmptyTrigger,
        setTrigger,
        essentialFeature,
        disabledProjects,
        fetchProjectsTrackedTriggers,
        deleteEssentialFeatureFromStore,
        saveTrackedTrigger,
        nothingToSave,
        openCustomTriggerDialog: throttle(openCustomTriggerDialog, 500, { trailing: false }),
        trackTrigger: throttle(trackTrigger, 500, { trailing: false }),
        editTrigger,
        fetchTriggerForUrl,
        deleteTrackedTriggerById
    };
});
