import http from '../plugins/http';
const ENDPOINT = 'invention-concepts';
export function getInventionConcepts(params) {
    return http.get(ENDPOINT, { params });
}
export function getInventionConceptTransitions(params) {
    return http.get(`${ENDPOINT}/transitions/`, { params });
}
export function getInventionConcept(id) {
    return http.get(`${ENDPOINT}/${id}`);
}
export function createInventionConcept(data) {
    return http.post(`${ENDPOINT}/`, data);
}
export function patchInventionConcept(inventionConceptId, data) {
    return http.patch(`${ENDPOINT}/${inventionConceptId}/`, data);
}
export function deleteInventionConcept(inventionConceptId) {
    return http.delete(`${ENDPOINT}/${inventionConceptId}`);
}
