export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    alias: '/',
    components: {
      default: () => import(/* webpackChunkName: "dashboard" */ '@/modules/dashboard/views/DashboardView'),
      search: () => import(/* webpackChunkName: "dashboard" */ '@/modules/dashboard/views/DashboardSearch')
    }
  }
]
