export default [
    {
        path: '/workbook/:projectId?/:canvasId?',
        name: 'workbook-new-window',
        component: () => import('@/layouts/WorkbookLayout.vue'),
        meta: {
            workbook: true
        }
    }
];
