import { render, staticRenderFns } from "./CanvasBlockComments.vue?vue&type=template&id=ade3667e&scoped=true&lang=pug"
import script from "./CanvasBlockComments.vue?vue&type=script&lang=ts"
export * from "./CanvasBlockComments.vue?vue&type=script&lang=ts"
import style0 from "./CanvasBlockComments.vue?vue&type=style&index=0&id=ade3667e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ade3667e",
  null
  
)

export default component.exports