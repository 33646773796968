export default [
    {
        path: '/project/new',
        name: 'project-new',
        meta: {
            title: 'Explore Concepts'
        },
        component: () => import(/* webpackChunkName: "projectform" */ '@/modules/projects/views/ProjectForm.vue')
    },
    {
        path: '/project/:project_id/edit',
        name: 'project-edit',
        meta: {
            title: 'Explore Concepts',
            hideCanvasPopover: true
        },
        component: () => import(/* webpackChunkName: "projectform" */ '@/modules/projects/views/ProjectForm.vue')
    },
    {
        path: '/project/:project_id/explore-ideas/table',
        name: 'explore-ideas',
        meta: {
            title: 'Explore Concepts'
        },
        component: () => import(/* webpackChunkName: "projecthome" */ '@/modules/projects/views/ProjectHome.vue')
    },
    {
        path: '/project/:project_id/explore-ideas/atlas',
        name: 'explore-ideas-atlas',
        meta: {
            title: 'Explore Concepts'
        },
        component: () => import(/* webpackChunkName: "projecthome" */ '@/modules/projects/views/ProjectHome.vue')
    }
];
