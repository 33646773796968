/**
 * Projects store
 */
import isString from 'lodash/isString';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { getProject, getProjects, getProjectUsers, partialUpdateProject } from '../../../api/projects';
import { useFlagsStore } from '../../../store/modules/flags';
import { useAsyncState } from '../../../utils/asyncState';
import { isValidUUID } from '../../../utils/validations';
export const projectsStore = defineStore('projects', () => {
    const currentProject = ref(null);
    const currentProjectUsers = ref(null);
    const allProjects = useAsyncState(async () => (await getProjects()).data, [], { allowParallel: false, debounceMs: 1000 });
    const allActive = computed(() => allProjects.state.value.filter((p) => !p.completed_date && !p.archived_date) || []);
    const missingGoal = computed(() => Boolean(currentProject.value && !currentProject.value.goal));
    const currentProjectId = computed(() => currentProject.value?.uuid);
    const currentProjectGoals = computed(() => currentProject.value?.goal?.split('\n') || []);
    const aiGenerationEnabled = computed(() => {
        return useFlagsStore().AI_CONCEPT_GENERATION_ENABLED && currentProject.value?.enable_ai_concept_generation;
    });
    async function fetchCurrent(projectId) {
        let newProjectId;
        if (isString(projectId) && isValidUUID(projectId)) {
            newProjectId = projectId;
        }
        else if (isString(currentProjectId.value) && isValidUUID(currentProjectId.value)) {
            newProjectId = currentProjectId.value;
        }
        if (!newProjectId)
            return null;
        currentProject.value = (await getProject(newProjectId)).data;
        currentProjectUsers.value = null;
        // @ts-ignore
        await this.getCurrentProjectUsers();
        return currentProject.value;
    }
    /**
     * Get the current project. Fetch it if necessary.
     */
    async function getCurrent() {
        if (currentProject.value)
            return currentProject.value;
        // @ts-ignore
        return this.fetchCurrent();
    }
    /**
     * Get the a specific project and set it as the current. Fetch it if necessary.
     */
    async function getProjectAndSetAsCurrent(projectId) {
        if (!isValidUUID(projectId))
            throw Error(`ProjectId ${projectId} is not a valid UUID!`);
        if (currentProject.value?.uuid === projectId)
            return currentProject.value;
        // @ts-ignore
        return this.fetchCurrent(projectId);
    }
    /**
     * Update the current project using the provided attributes
     */
    async function updateCurrent(attributes) {
        if (currentProjectId.value)
            currentProject.value = (await partialUpdateProject(currentProjectId.value, attributes)).data;
        return currentProject.value;
    }
    /**
     * Get the current project users. Fetch it if necessary.
     */
    async function getCurrentProjectUsers() {
        if (currentProjectUsers.value)
            return currentProjectUsers.value;
        if (currentProjectId.value)
            currentProjectUsers.value = (await getProjectUsers(currentProjectId.value)).data;
        return currentProjectUsers.value;
    }
    return {
        currentProject,
        currentProjectUsers,
        allProjects: allProjects.state,
        allActive,
        currentProjectId,
        fetchCurrent,
        getCurrent,
        getProjectAndSetAsCurrent,
        updateCurrent,
        getCurrentProjectUsers,
        fetchAll: allProjects.execute,
        missingGoal,
        currentProjectGoals,
        aiGenerationEnabled
    };
});
