export const THEMES = {
    AUTO: 'auto',
    DARK: 'dark',
    LIGHT: 'light'
};
export const REQUEST_STATUS = {
    LOADING: 'Loading',
    SUCCESS: 'Success',
    ERROR: 'Error'
};
export const IC_STATUS = {
    NEW: 'NEW',
    DISCARDED: 'DSCRDD',
    REWORK: 'REWORK',
    IO: 'IO',
    STOP: 'STOP',
    REVIEW: 'REVIEW',
    ONHOLD: 'ONHOLD',
    USED: 'USED',
    ACCEPTED: 'ACCPTD',
    REVIEW_REWORKED: 'RaREW',
    REJECTED: 'REJCTD'
};
export const IC_STATUS_TEXT = {
    NEW: 'New',
    DSCRDD: 'Discarded',
    REWORK: 'Rework',
    IO: 'IO',
    STOP: 'Stop',
    REVIEW: 'Review',
    ONHOLD: 'On hold',
    USED: 'Used',
    ACCPTD: 'Accepted',
    RaREW: 'Review (Reworked)',
    REJCTD: 'Rejected'
};
export const AUTHENTICATION_TYPES = {
    PASSWORD: 'password',
    ACTIVE_DIRECTORY_SSO: 'ad_sso',
    ACTIVE_DIRECTORY_SSO_TRUSTED: 'ad_sso_trusted'
};
export const SIGNON_METHOD = {
    ACTIVE_DIRECTORY: 'AD',
    USERNAME_PASSWORD: 'PWD'
};
export const TRACKED_TRIGGER_UNRATED_VALUE = 'N/A';
export const TRACKED_TRIGGER_GOLDEN = 'Trending';
export const TRACKED_TRIGGER_RATING_OPTIONS = {
    A: { text: 'A', value: 'A', guidance: 'High inventive potential' },
    B: { text: 'B', value: 'B', guidance: 'Moderate inventive potential' },
    C: { text: 'C', value: 'C', guidance: 'Background' },
    F: { text: 'F', value: 'F', guidance: 'Not relevant' },
    [TRACKED_TRIGGER_UNRATED_VALUE]: { text: 'N/A', value: TRACKED_TRIGGER_UNRATED_VALUE, guidance: 'Not rated yet' }
};
export const TRACKED_TRIGGER_RATING_COLOR = {
    A: 'green lighten-1',
    B: 'light-green lighten-2',
    C: 'lime lighten-2',
    F: 'yellow lighten-2'
};
export const USED_TRIGGER_STATUS = 'USED';
export const TRACKED_TRIGGER_STATUS_COLOR = {
    OPEN: 'success',
    CLOSED: 'error',
    [USED_TRIGGER_STATUS]: 'iprovaLight'
};
export const ESSENTIAL_FEATURE_SUB_TYPES = {
    EF: 'ef',
    EFIC: 'efic'
};
export const ESSENTIAL_FEATURE_TYPES = {
    ABILITY: 'Advance',
    TREND: 'Trend',
    PROBLEM: 'Problem',
    BACKGROUND: 'Background',
    PRIOR_ART: 'Prior Art'
};
export const ESSENTIAL_FEATURE_COLORS = {
    ABILITY: '#01CD0D',
    TREND: '#EBAE5F',
    PROBLEM: '#ff5252',
    BACKGROUND: '#7502AE',
    PRIOR_ART: '#EC1414'
};
export const PROJECT_STATUS = {
    ONGOING: 'ONGOING',
    ARCHIVED: 'ARCHIVED',
    COMPLETED: 'COMPLETED'
};
export const ASPECT_TYPES = {
    nli: 'Natural Language'
};
export const BLOCK_TYPES = {
    FILE: 'FILE',
    CANVAS: 'CANVAS',
    TEXT_EDITOR: 'TEXT_EDITOR'
};
export var DocumentType;
(function (DocumentType) {
    DocumentType["PATENT"] = "patent";
    DocumentType["JOURNAL"] = "journal";
    DocumentType["MEDIA"] = "media";
    DocumentType["STANDARD"] = "standard";
    DocumentType["DOCUMENT"] = "document";
})(DocumentType || (DocumentType = {}));
export const DOCUMENT_TYPE_ICONS = {
    [DocumentType.JOURNAL]: 'mdi-school-outline',
    [DocumentType.PATENT]: 'mdi-certificate-outline',
    [DocumentType.STANDARD]: 'mdi-receipt-text-check',
    [DocumentType.MEDIA]: 'mdi-newspaper-variant-outline'
};
export const dateToIsoDate = (date) => date.toISOString().split('T')[0];
export const IWB_TRACKED_TRIGGER_SOURCE = {
    TRIGGER_HUNTING_WIDGET: 'Canvas triggers widget'
};
// This is to be used when we want to limit the number of rows in a textarea to 9 rows and
// dynamically adjust the height of the textarea based on the content
export const TEXT_AREA_9_ROWS_IN_PIXELS = 252;
