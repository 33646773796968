/**
 * Pinia store to manage the authentication
 */
import Vue from 'vue';
import isString from 'lodash/isString';
import { AUTHENTICATION_TYPES } from '../../utils/constants';
import { defineStore } from 'pinia';
import { appStore } from '@/store/modules/app';
import { getUsersProfile } from '../../api/users';
import { authActiveDirectorySSO, getLoginRoutes, getSigninFlow, impersonate, logout } from '../../api/auth';
import { emptyProfile } from '../../interfaces/auth';
import store from '@/store';
import setCookie from '../../utils/setCookie';
import { getUserPreferences } from '../../api/preferences';
import { useConfigStore } from './configuration';
function formatRoute(route) {
    // Remove ['/api', 'api'] and then '/' at the beginning of the route
    return route.replace(/^.?api/, '').replace(/^\/?/, '');
}
export const authStore = defineStore('auth', {
    state: () => ({
        sessionToken: null,
        isPopulated: false,
        authenticationRoutes: {},
        promiseWhenLogin: undefined,
        resolveWhenLogin: undefined,
        preferences: {},
        profile: emptyProfile()
    }),
    getters: {
        displayName(state) {
            return state.profile.first_name || state.profile.last_name || state.profile.username;
        },
        fullName(state) {
            let fullName = state.profile.first_name || '';
            if (state.profile.last_name)
                fullName += ` ${state.profile.last_name}`;
            if (fullName === '')
                fullName = state.profile.username;
            return fullName;
        },
        isAuthenticated(state) {
            // The reason we get the token here is to check if they are currently store in the localStorage
            // but not in the store. The reason we dispatch again is because we want to check again the
            // token validity after tried to get it again
            return Boolean(state.profile.user_id);
        },
        getProjectAssignment: (state) => (projectId) => (state.profile.project_assignments.find((pa) => pa.project_id === projectId)),
        isProjectManager: (state) => (projectId) => {
            // @ts-ignore
            const pa = state.getProjectAssignment(projectId);
            return Boolean(pa) && pa.is_project_manager;
        },
        isInventionDeveloper: (state) => (projectId) => {
            // @ts-ignore
            const pa = state.getProjectAssignment(projectId);
            return Boolean(pa) && pa.is_invention_developer;
        }
    },
    actions: {
        async fetchProfile() {
            try {
                const [profile, preferences] = await Promise.all([getUsersProfile(), getUserPreferences()]);
                this.profile = profile.data;
                appStore().setRUMUser(this.profile);
                this.preferences = preferences.data;
                await useConfigStore().fetchConfigs();
            }
            catch (e) {
                console.error('Error while fetching profile', e);
                // not authenticated. doesn't matter
            }
            return this.profile;
        },
        async startAadSSOAuthFlow() {
            const flow = (await getSigninFlow()).data;
            localStorage.setItem('aadSSOFlow', JSON.stringify(flow));
            window.location.href = flow.auth_uri;
        },
        async finishAadSSOAuthFlow(requestGetParams) {
            const flow = JSON.parse(localStorage.getItem('aadSSOFlow') ?? '{}');
            if (!flow)
                return;
            localStorage.removeItem('aadSSOFlow');
            await this.login(AUTHENTICATION_TYPES.ACTIVE_DIRECTORY_SSO, () => authActiveDirectorySSO(requestGetParams, JSON.stringify(flow)));
        },
        async login(type, method) {
            let route;
            if (isString(type)) {
                const routeObj = await this.getRoute(type);
                if (!routeObj)
                    throw Error(`No route for authentication method ${type}`);
                route = routeObj.route;
            }
            this.profile = (await method(route)).data;
            await useConfigStore().fetchConfigs();
            if (this.resolveWhenLogin)
                this.resolveWhenLogin();
        },
        async logout() {
            await logout();
            setCookie('sessionid', '', 0);
            this.profile = emptyProfile();
            this.resetLoginPromise();
            appStore().resetRUMUser();
        },
        async waitForAuthenticated() {
            if (this.isAuthenticated)
                return;
            if (!this.promiseWhenLogin)
                this.resetLoginPromise();
            return this.promiseWhenLogin;
        },
        setRoute({ type, route, meta, display }) {
            if (!Object.values(AUTHENTICATION_TYPES).find(i => i === type)) {
                throw new Error(`Type ${type} is not a valid authentication type!
        Valid: ${Object.values(AUTHENTICATION_TYPES).join(', ')}`);
            }
            Vue.set(this.authenticationRoutes, type, {
                route: formatRoute(route),
                display,
                meta
            });
        },
        async populateLoginRoutes() {
            const loginRoutes = (await getLoginRoutes()).data;
            for (const authType of Object.values(AUTHENTICATION_TYPES)) {
                if (Object.hasOwn(loginRoutes, authType)) {
                    const authObject = loginRoutes[authType];
                    this.setRoute({
                        type: authType,
                        route: authObject.route,
                        display: authObject.display,
                        meta: authObject.meta
                    });
                }
            }
            this.isPopulated = true;
        },
        async getRoute(type) {
            if (!this.isPopulated)
                await this.populateLoginRoutes();
            if (!Object.hasOwn(this.authenticationRoutes, type))
                return;
            return this.authenticationRoutes[type];
        },
        async impersonate(userEmail) {
            try {
                this.profile = (await impersonate({ email: userEmail })).data;
                return true;
            }
            catch (e) {
                console.error('Error while impersonating', e);
                store.commit('snackbar/show', {
                    color: 'error',
                    message: `Failed to impersonate ${userEmail}. Please contact support.`
                });
                return false;
            }
        },
        resetLoginPromise() {
            this.promiseWhenLogin = new Promise(resolve => {
                this.resolveWhenLogin = resolve;
            });
        }
    }
});
