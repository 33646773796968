import { defineStore, storeToRefs } from 'pinia';
import Vue, { computed, ref, watch } from 'vue';
import { ideaCreationSpaceStore } from './ideaCreationSpace';
import { createCandidateIdea, createIdeaGenerationRequest, deleteCandidateIdea, getIdeaGenerationRequest, getRefinementOptions, patchCandidateIdea, reassessCandidateIdea, saveInvention } from '../../../api/ideaGeneration';
import { projectsStore } from '../../projects/store';
import sleep from '../../../utils/sleep';
import { throttle } from 'lodash/function';
import store from '@/store';
import cloneDeep from 'lodash/cloneDeep';
import { useSelectedInventionConceptStore } from '../../invention_concepts/store/selectedInventionConceptStore';
import { makeIdeaRefinementState } from './state_helpers/refinementState';
import isEqual from 'lodash/isEqual';
import dayjs from 'dayjs';
import { useInventionConceptsStore } from '@/modules/invention_concepts/store/inventionConceptsStore';
import { useTrackedTriggersStore } from '@/modules/triggers/store/trackedTriggers';
const MIN_WORD_COUNT_FOR_CONCEPT = 15;
const MIN_WORD_COUNT_FOR_PROBLEM = 5;
const wordCount = (str) => [...str?.matchAll(/\w+/g) ?? []].length;
const selectionExtractor = /<selection>(.+)<\/selection>/;
function extractSelection(selectedContent) {
    return selectionExtractor.exec(`${selectedContent.title} - ${selectedContent.concept}`)?.[1] ?? null;
}
export const ideaRefinementStore = defineStore('idea-refinement', () => {
    const ideaCreationSpace = ideaCreationSpaceStore();
    const selectedInventionConceptStore = useSelectedInventionConceptStore();
    const inventionConceptsStore = useInventionConceptsStore();
    const trackedTriggersStore = useTrackedTriggersStore();
    const { selectedIdea } = storeToRefs(ideaCreationSpace);
    const refinementState = computed(() => ideaCreationSpace.refinementStates[ideaCreationSpace.selectedIndex]);
    const isRefining = computed(() => {
        return Boolean(refinementState.value?.fetchingOptions || refinementState.value?.regeneratingConcept);
    });
    const openRequest = ref(false);
    const selectedAction = ref(null);
    const dateRanges = ref(['2000-01-01', dayjs().add(1, 'year').format('YYYY-MM-DD')]);
    const mainTriggers = computed(() => (selectedIdea.value?.triggers ?? []).filter(t => t.is_main_trigger));
    const supportingTriggers = computed(() => (selectedIdea.value?.triggers ?? []).filter(t => !t.is_main_trigger));
    async function getRefinementOptionsForSelectedIdea(action, selectedContent) {
        if (!selectedIdea.value || isRefining.value)
            return;
        const currentRefinementState = refinementState.value;
        currentRefinementState.action = action;
        currentRefinementState.selectedContent = selectedContent;
        currentRefinementState.fetchingOptions = true;
        currentRefinementState.conceptRegenerated = false;
        currentRefinementState.triggers = [];
        currentRefinementState.refinementTextToDisplay = null;
        try {
            selectedAction.value = action;
            const serializedData = {
                action,
                title: selectedContent.title,
                concept: selectedContent.concept,
                project_background: projectsStore().currentProject?.background || 'blank',
                date_from: dateRanges.value[0],
                date_to: dateRanges.value[1],
                must_not_match: selectedIdea.value.must_not_match
            };
            const response = await getRefinementOptions(serializedData);
            currentRefinementState.triggers = response.data.suggested_triggers;
            currentRefinementState.refinementTextToDisplay = response.data.text_to_display || null;
        }
        finally {
            currentRefinementState.fetchingOptions = false;
        }
    }
    async function refineUsingTrigger(triggers) {
        if (!selectedIdea.value || isRefining.value)
            return;
        refinementState.value.regeneratingConcept = true;
        try {
            const triggersMapped = selectedIdea.value?.triggers?.map((trigger) => ({ ...trigger, doc_type: trigger.doc_type?.toUpperCase() })) ?? [];
            const selectedTriggers = triggers?.length ? triggers.map((trigger) => ({ ...trigger, doc_type: trigger.doc_type?.toUpperCase() })) : [];
            let regenerationRequest = await createIdeaGenerationRequest({
                goal: selectedIdea.value?.content?.problem,
                triggers: triggersMapped,
                selected_triggers: selectedTriggers,
                concept_to_refine: selectedIdea.value?.uuid,
                project: projectsStore().currentProjectId,
                action: refinementState.value.action,
                parent: selectedIdea.value?.parent,
                selected_text: extractSelection(refinementState.value.selectedContent) ?? '',
                must_not_match: selectedIdea.value.must_not_match
            });
            let inserted = false;
            while (regenerationRequest.data.state === 'CREATED' || regenerationRequest.data.state === 'COMPUTING') {
                await sleep(1000);
                regenerationRequest = await getIdeaGenerationRequest(regenerationRequest.data.uuid);
                if (regenerationRequest.data.ideas.length > 0) {
                    if (!inserted) {
                        Vue.set(refinementState.value.ideaStack, refinementState.value.currentIndex + 1, regenerationRequest.data.ideas[0]);
                        Vue.set(refinementState.value.editionTracker, refinementState.value.currentIndex + 1, {
                            assessed: cloneDeep(regenerationRequest.data.ideas[0].content),
                            initial: cloneDeep(regenerationRequest.data.ideas[0].content)
                        });
                        refinementState.value.currentIndex += 1;
                        inserted = true;
                    }
                    else {
                        refinementState.value.ideaStack[refinementState.value.ideaStack.length - 1].scores = regenerationRequest.data.ideas[0].scores;
                    }
                }
            }
            refinementState.value.action = '';
            refinementState.value.conceptRegenerated = true;
        }
        finally {
            refinementState.value.regeneratingConcept = false;
        }
    }
    const makeWarningMessageGuard = (member, minWordCount) => {
        return computed(() => {
            if (!selectedIdea.value)
                return '';
            if (member === 'title') {
                if (selectedIdea.value?.content.title.trim() === '') {
                    return 'Enter a title to generate assessments.';
                }
            }
            else if (wordCount(selectedIdea.value?.content[member]) < minWordCount) {
                return `Enter a ${member} of at least ${minWordCount} words to generate assessments.`;
            }
            return '';
        });
    };
    const getTrackedTriggerLoc = (url) => {
        const tt = trackedTriggersStore.trackedTriggers.find(tt => tt.url === url);
        return {
            location_x: tt?.location_x ?? undefined,
            location_y: tt?.location_y ?? undefined
        };
    };
    const addTriggers = async ({ triggers, shouldRecompute }) => {
        const currentSelectedIdea = selectedIdea.value;
        if (!currentSelectedIdea)
            return;
        if (shouldRecompute) {
            refinementState.value.action = 'EXPAND';
            await refineUsingTrigger(triggers);
        }
        else {
            try {
                const response = await patchCandidateIdea(selectedIdea.value.uuid, {
                    triggers: [
                        ...selectedIdea.value?.triggers ?? [],
                        ...triggers.filter(t => !(selectedIdea.value?.triggers ?? []).find(t2 => t2.url === t.url && t2.text === t.text))
                    ].map(t => ({ ...t, ...getTrackedTriggerLoc(t.url) }))
                });
                currentSelectedIdea.triggers = response.data.triggers;
                await inventionConceptsStore.getAllInventionConcepts();
            }
            catch (e) {
                store.commit('snackbar/show', {
                    color: 'error',
                    message: 'Failed to add triggers.'
                });
            }
        }
    };
    const removeTrigger = async (trigger) => {
        const currentSelectedIdea = selectedIdea.value;
        if (!currentSelectedIdea)
            return;
        const currentTriggers = cloneDeep(selectedIdea.value?.triggers ?? []);
        try {
            const triggers = (selectedIdea.value?.triggers ?? [])
                .filter(t => t.url !== trigger.url || t.text !== trigger.text)
                .map(t => ({ ...t, ...getTrackedTriggerLoc(t.url) }));
            const response = await patchCandidateIdea(selectedIdea.value.uuid, { triggers });
            currentSelectedIdea.triggers = response.data.triggers;
            showUndoMessage(currentSelectedIdea, currentTriggers);
            await inventionConceptsStore.getAllInventionConcepts();
        }
        catch (e) {
            store.commit('snackbar/show', {
                color: 'error',
                message: 'Failed to remove trigger.'
            });
        }
    };
    const showUndoMessage = (currentSelectedIdea, currentTriggers) => {
        store.commit('snackbar/show', {
            color: 'success',
            message: 'Trigger successfully removed.',
            extraActions: [{
                    title: 'Undo',
                    action: async () => {
                        try {
                            const response = await patchCandidateIdea(currentSelectedIdea.uuid, {
                                triggers: currentTriggers
                            });
                            currentSelectedIdea.triggers = response.data.triggers;
                            await inventionConceptsStore.getAllInventionConcepts();
                        }
                        catch (e) {
                            store.commit('snackbar/show', {
                                color: 'error',
                                message: 'Failed to undo trigger removal.'
                            });
                        }
                    }
                }]
        });
    };
    const titleWarningMessage = makeWarningMessageGuard('title', 0);
    const conceptWarningMessage = makeWarningMessageGuard('concept', MIN_WORD_COUNT_FOR_CONCEPT);
    const problemWarningMessage = makeWarningMessageGuard('problem', MIN_WORD_COUNT_FOR_PROBLEM);
    const hasWarningMessage = computed(() => Boolean(titleWarningMessage.value || conceptWarningMessage.value || problemWarningMessage.value));
    const canReassess = computed(() => {
        if (!refinementState.value || refinementState.value.reassessingConcept)
            return false;
        return !isEqual(refinementState.value.ideaStack[refinementState.value.currentIndex].content, refinementState.value.editionTracker[refinementState.value.currentIndex].assessed);
    });
    const canRevert = computed(() => {
        if (!refinementState.value)
            return false;
        return !isEqual(refinementState.value.ideaStack[refinementState.value.currentIndex].content, refinementState.value.editionTracker[refinementState.value.currentIndex].initial);
    });
    const triggers = ref([]);
    const savingAsInventionConcept = ref(false);
    async function saveAsInventionConcept(mustNotMatch) {
        if (savingAsInventionConcept.value || !selectedIdea.value)
            return;
        const state = refinementState.value;
        const ideaToSave = state.savedAsInventionConcept ?? selectedIdea.value;
        savingAsInventionConcept.value = true;
        try {
            const response = await saveInvention(ideaToSave.uuid, {
                triggers: selectedIdea.value?.triggers,
                content: selectedIdea.value?.content,
                must_not_match: mustNotMatch
            });
            state.savedAsInventionConcept = ideaToSave;
            await selectedInventionConceptStore.getSelectedIc(response.data.invention_concept_id);
            await trackedTriggersStore.fetchTrackedTriggers();
            return response.data;
        }
        finally {
            savingAsInventionConcept.value = false;
        }
    }
    async function saveAsInventionConceptFromCanvas(idea, canvasBlockIds) {
        try {
            const response = await saveInvention(idea.uuid, {
                triggers: idea.triggers,
                content: idea.content
            });
            if (canvasBlockIds.length) {
                await selectedInventionConceptStore.updateICCanvasBlocks(response.data.invention_concept_id, canvasBlockIds);
            }
            return response.data;
        }
        catch (e) {
            store.commit('snackbar/show', {
                color: 'error',
                message: 'Failed to save as Invention Concept.'
            });
        }
    }
    const addTriggersToIdeaFromCanvas = async (idea, triggers) => {
        try {
            const response = await patchCandidateIdea(idea.uuid, {
                triggers: [
                    ...idea.triggers ?? [],
                    ...triggers.filter(t => !(idea.triggers ?? []).find(t2 => t2.url === t.url))
                ]
            });
            idea.triggers = response.data.triggers;
        }
        catch (e) {
            store.commit('snackbar/show', {
                color: 'error',
                message: 'Failed to add triggers.'
            });
        }
    };
    async function toggleBookmark() {
        const bookmarkedIdea = refinementState.value.bookmarkedConcept;
        if (openRequest.value || !selectedIdea.value)
            return;
        openRequest.value = true;
        try {
            if (bookmarkedIdea) {
                await patchCandidateIdea(bookmarkedIdea.uuid, { bookmarked: false });
                refinementState.value.bookmarkedConcept = undefined;
            }
            else {
                await patchCandidateIdea(selectedIdea.value.uuid, { bookmarked: true });
                selectedIdea.value.bookmarked = true;
                refinementState.value.bookmarkedConcept = selectedIdea.value;
            }
        }
        finally {
            openRequest.value = false;
        }
    }
    async function reassessConcept() {
        if (!selectedIdea.value || !canReassess.value)
            return;
        refinementState.value.reassessingConcept = true;
        const currentSelectedIdea = selectedIdea.value;
        try {
            const scoresResponse = await reassessCandidateIdea(currentSelectedIdea.uuid);
            currentSelectedIdea.scores = scoresResponse.data.scores;
            refinementState.value.editionTracker[refinementState.value.currentIndex].assessed = cloneDeep(refinementState.value.ideaStack[refinementState.value.currentIndex].content);
        }
        finally {
            refinementState.value.reassessingConcept = false;
        }
    }
    async function revertConcept() {
        if (!selectedIdea.value || !canRevert.value)
            return;
        refinementState.value.ideaStack[refinementState.value.currentIndex].content = cloneDeep(refinementState.value.editionTracker[refinementState.value.currentIndex].initial);
        await patchCandidateIdea(selectedIdea.value.uuid, { content: refinementState.value.ideaStack[refinementState.value.currentIndex].content });
    }
    async function updateContent(member, value) {
        if (!selectedIdea.value)
            return;
        const currentSelectedIdea = selectedIdea.value;
        if (member === 'main_trigger' && typeof value !== 'string') {
            const newMainTrigger = currentSelectedIdea.triggers?.find(t => t.text === value.text && t.url === value.url);
            const oldMainTriggers = currentSelectedIdea.triggers?.filter(t => t.is_main_trigger);
            if (!newMainTrigger || !oldMainTriggers)
                return;
            oldMainTriggers.forEach(t => {
                t.is_main_trigger = false;
            });
            newMainTrigger.is_main_trigger = true;
            const response = await patchCandidateIdea(currentSelectedIdea.uuid, { triggers: currentSelectedIdea.triggers });
            currentSelectedIdea.triggers = response.data.triggers;
        }
        else if (member !== 'main_trigger' && typeof value === 'string') {
            currentSelectedIdea.content[member] = value;
            const response = await patchCandidateIdea(currentSelectedIdea.uuid, { content: currentSelectedIdea.content });
            currentSelectedIdea.content[member] = response.data.content[member];
        }
    }
    function changeSelectedIdea(event) {
        const index = refinementState.value.ideaStack.findIndex(idea => idea.uuid === event.ideaUuid);
        if (index < 0)
            return;
        refinementState.value.currentIndex = index;
        selectedIdea.value.content[event.key] = event.content;
    }
    const refinementSuggestionDescription = computed(() => {
        if (refinementState.value?.refinementTextToDisplay)
            return refinementState.value.refinementTextToDisplay;
        if (!selectedIdea.value || !refinementState.value.action || !refinementState.value.selectedContent)
            return '';
        const actionText = {
            SWAP: 'alternative options for',
            EXPAND: 'options to expand on',
            CREATE: 'UNSUPPORTED'
        }[refinementState.value.action];
        return `Searching ${actionText} "${extractSelection(refinementState.value.selectedContent)}"...`;
    });
    async function duplicateConcept() {
        if (openRequest.value)
            return;
        const currentProject = projectsStore().currentProjectId;
        if (!selectedIdea.value || !currentProject)
            return;
        openRequest.value = true;
        try {
            const response = await createCandidateIdea({
                content: selectedIdea.value?.content,
                triggers: selectedIdea.value?.triggers,
                idea_type: selectedIdea.value?.idea_type
            }, currentProject);
            ideaCreationSpace.refinementStates.splice(0, 0, makeIdeaRefinementState(response.data));
            ideaCreationSpace.selectedIndex = 0;
        }
        finally {
            openRequest.value = false;
        }
    }
    async function deleteConcept() {
        if (openRequest.value || !selectedIdea.value)
            return;
        openRequest.value = true;
        try {
            await deleteCandidateIdea(refinementState.value.ideaStack[0].uuid);
            ideaCreationSpace.refinementStates.splice(ideaCreationSpace.selectedIndex, 1);
            ideaCreationSpace.selectedIndex = Math.max(0, ideaCreationSpace.selectedIndex - 1);
        }
        finally {
            openRequest.value = false;
        }
    }
    watch(dateRanges, async () => {
        if (selectedAction.value)
            await getRefinementOptionsForSelectedIdea(selectedAction.value, refinementState.value.selectedContent);
    });
    return {
        getRefinementOptionsForSelectedIdea,
        refinementState,
        refineUsingTrigger,
        titleWarningMessage,
        conceptWarningMessage,
        problemWarningMessage,
        hasWarningMessage,
        selectedIdea,
        triggers,
        isRefining,
        saveAsInventionConcept,
        savingAsInventionConcept,
        toggleBookmark: throttle(toggleBookmark, 500, { trailing: false }),
        updateContent,
        changeSelectedIdea,
        refinementSuggestionDescription,
        addTriggers,
        removeTrigger: throttle(removeTrigger, 500, { trailing: false }),
        openRequest,
        duplicateConcept,
        deleteConcept,
        reassessConcept,
        canReassess,
        revertConcept,
        canRevert,
        dateRanges,
        mainTriggers,
        supportingTriggers,
        saveAsInventionConceptFromCanvas,
        addTriggersToIdeaFromCanvas
    };
});
